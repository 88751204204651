import * as React from "react"
import { motion, AnimatePresence } from "framer-motion"
import type { ComponentType } from "react"
import { createStore } from "https://framer.com/m/framer/store.js@^1.0.0"

// 圖像列表
const images = [
    "https://yuez.tw/wedding/w01.jpg",
    "https://yuez.tw/wedding/w02.jpg",
    "https://yuez.tw/wedding/w03.jpg",
    "https://yuez.tw/wedding/w04.jpg",
]

// 隨機選擇一個圖像作為初始背景
const initialBackground = images[Math.floor(Math.random() * images.length)]

const useStore = createStore({
    background: initialBackground,
})

export function withRandomImage(Component): ComponentType {
    return (props) => {
        const [store, setStore] = useStore() // 加入更新存儲的功能
        const [index, setIndex] = React.useState(0) // 用於控制當前圖片索引

        // 定時器用於每隔幾秒切換一次圖片
        React.useEffect(() => {
            const interval = setInterval(() => {
                // 切換到下一張圖片
                const nextIndex = (index + 1) % images.length
                setIndex(nextIndex)
                const nextBackground = images[nextIndex]
                setStore({ background: nextBackground })
            }, 5000) // 每5秒切換一次圖片

            return () => clearInterval(interval) // 清除定時器
        }, [index, setStore])

        return (
            <AnimatePresence>
                <motion.div
                    key={store.background}
                    {...props}
                    initial={{ opacity: 0, scale: 1 }}
                    animate={{ opacity: 1, scale: 1.1 }}
                    exit={{ opacity: 0 }}
                    transition={{
                        opacity: { duration: 1 },
                        scale: { duration: 5, ease: "easeInOut" },
                    }}
                    style={{
                        backgroundImage: `url(${store.background})`,
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                        backgroundRepeat: "no-repeat",
                        borderRadius: "20px", // 保留圓角效果
                        position: "absolute",
                        width: "100%",
                        height: "100%",
                        top: 0,
                        left: 0,
                    }}
                />
            </AnimatePresence>
        )
    }
}

// 使用示例元件
function MyComponent() {
    const RandomImageFrame = withRandomImage(motion.div)

    return (
        <div
            style={{
                position: "relative",
                width: "100%",
                height: "100vh",
                borderRadius: "20px",
                overflow: "hidden",
            }}
        >
            <RandomImageFrame />
            <div
                style={{
                    position: "absolute",
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    zIndex: 1,
                    borderRadius: "20px", // 保留圓角效果
                }}
            >
                Welcome to the site!
            </div>
        </div>
    )
}

export default MyComponent
